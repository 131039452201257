<template>
  <div class="home">
    <AppHeader />

    <div class="content-wrapper">
      <div class="content">
        <p><b>The Glitch Fairy</b> is a fast-action platformer, with a unique "<b>Glitch</b>" mechanic. <br><br>
        Available now on <b>Steam</b>!</p>
        <div class="steam-link">
          <a href="https://store.steampowered.com/app/1811910/The_Glitch_Fairy/" target="_blank">
            <img src="@/assets/images/steam page.png" alt="Screenshot 2">
          </a>
        </div>
        <div class="video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/QcM3dELVczE?si=DkPcYkAjswnxCTzi"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="steam-link">
          <a href="https://impress.games/press-kit/cannon-cow/the-glitch-fairy" target="_blank">
            <img src="@/assets/images/press kit.png" alt="Screenshot 2">
          </a>
        </div>
      </div>

      <div class="content">
        <div class="images ibury">
          <img src="@/assets/images/ibury sticker final.png" alt="Screenshot 1">
        </div>
        <SocialLinks />
        <div class="images erin">
          <img src="@/assets/images/erin sticker final.png" alt="Screenshot 1">
        </div>
        <StoreLinks />
      </div>
    </div>

    <AppFooter />
  </div>
</template>


<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import SocialLinks from '@/components/SocialLinks.vue'
import StoreLinks from '@/components/StoreLinks.vue'

export default {
  name: 'AppHome',
  components: {
    AppHeader,
    AppFooter,
    SocialLinks,
    StoreLinks
  }
}
</script>

<style scoped>
.home {
  text-align: center;
  background-image: url('@/assets/images/BG.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 20px auto;
}

.content {
  padding: 20px;
  color: black;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  flex: 1;
  min-width: 300px; /* Ensures a minimum width for each content block */
}

.video {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.images img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.steam-link {
  margin-top: 20px;
}

.steam-link img {
  width: 100%;
  max-width: 600px;
  height: auto;
  cursor: pointer;
}

.follow-us p {
  font-size: 5rem;
  font-weight: bold;
  color: black;
  margin: 0;
}

.erin {
  background: linear-gradient(45deg, #b2a1cf, #3e1880);
  border-radius: 20px;
  border-style: ridge;
}

.ibury {
  background: linear-gradient(45deg, #c55454, #a70404);
  border-radius: 20px;
  border-style: ridge;
  margin: 0;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .video iframe {
    width: 100%;
    height: auto;
  }

  .steam-link img {
    max-width: 80%;
  }

  .content {
    padding: 10px;
  }

  .images img {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .steam-link img {
    max-width: 100%;
  }

  .images img {
    max-width: 150px;
  }
}
</style>

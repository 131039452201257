<template>
    <div class="store-links">
      <a href="https://youkaimountain.com/collections/glitch-fairy" target="_blank">Merch Store</a>
      <a href="https://cannoncow.itch.io/the-glitch-fairy" target="_blank">Itch.io</a>
      <a href="https://www.gog.com/en/game/the_glitch_fairy" target="_blank">GOG</a>
      <a href="https://store.epicgames.com/en-US/p/the-glitch-fairy-1e0211" target="_blank">Epic Games (Coming Soon)</a>
      <!-- Add more links as needed -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'StoreLinks'
  }
  </script>
  
  <style scoped>
  .store-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  a {
    color: #3b5998; /* Adjust color as needed */
    text-decoration: none;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f0; /* Optional: add background for better visibility */
  }
  
  a:hover {
    background-color: #ddd; /* Optional: add hover effect */
  }
  </style>
  